// import { createRouter, createWebHistory } from 'vue-router'
import { createRouter, createWebHashHistory  } from 'vue-router'

// firebase imports
import { auth } from '../firebase/config'

const requireAuth = (to, from, next) => {
  let user = auth.currentUser
  console.log(user)
  if (!user) {
    // next({ name: 'SignIn' })
    next()
  } else {
    next()
  }

  next()
}

const requireAuthGuest = (to, from, next) => {
  let user = auth.currentUser
  console.log(user)
  if (!user) {
    next({ name: 'RegSignIn' })
  } else {
    next()
  }

  next()
}

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: () => import('../views/LandingPage.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/:uid',
    name: 'LandingPageUID',
    component: () => import('../views/LandingPage.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: "/auth",
    name: "auth",
    component: () => import('../views/Auth.vue'),

    children: [
      {
        path: "",
        name: "SignIn",
        component: () => import('../views/auth/SignIn.vue'),
      },
      {
        path: "signup",
        name: "SignUp",
        component: () => import('../views/auth/SignUp.vue'),
      },
      {
        path: "change-password",
        name: "ChangePassword",
        component: () => import('../views/auth/ChangePassword.vue'),
      },
    ],
  },
  {
    path: "/registration/login",
    name: "RegSignIn",
    component: () => import('@/views/reg/SignIn.vue'),
  },
  {
    path: "/registration/signup",
    name: "RegSignUp",
    component: () => import('@/views/reg/SignUp.vue'),
  },
  {
    path: "/registration",
    name: "Registration",
    component: () => import('@/views/reg/Registration.vue'),
    beforeEnter: requireAuthGuest,
    children: [
      {
        path: "",
        name: "RegLanding",
        component: () => import('@/views/reg/RegLanding.vue'),
      },
      {
        path: "passport",
        name: "RegistrationPassport",
        component: () => import('@/views/reg/RegistrationPassport.vue'),
      },
      {
        path: "visa",
        name: "RegistrationVisa",
        component: () => import('@/views/reg/RegistrationVisa.vue'),
      },
      {
        path: "demographic",
        name: "RegistrationDemographic",
        component: () => import('@/views/reg/RegistrationDemographic.vue'),
      },
    ]
  },
  { path: "/:catchAll(.*)", component: import('../views/PageNotFound.vue') }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
