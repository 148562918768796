import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/styles.css'
import './assets/main.css'
// import './assets/tailwind.css'

createApp(App).use(store).use(router).mount('#app')


// // firebase imports
// import { auth } from './firebase/config'
// import { onAuthStateChanged } from 'firebase/auth'

// let app

// onAuthStateChanged(auth, () => {
//   if (!app) {
//     app = createApp(App).use(store).use(router).mount('#app')
//   }
// })

