import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage, ref } from 'firebase/storage'

// const firebaseConfig = {
//   apiKey: "AIzaSyCThpQsGd_8rUwGd-t9qCmYN4x3e_uVbVs",
//   authDomain: "vue-3-fb9.firebaseapp.com",
//   projectId: "vue-3-fb9",
//   storageBucket: "vue-3-fb9.appspot.com",
//   messagingSenderId: "544877178259",
//   appId: "1:544877178259:web:78d7ae682ca4ea36224c66"
// }

const firebaseConfig = {
  apiKey: 'AIzaSyCiu2kGqFrxrR9OZUh28Pqxns2ONZ9PCAw',
  authDomain: 'green-f4642.firebaseapp.com',
  projectId: 'green-f4642',
  storageBucket: 'green-f4642.appspot.com',
  messagingSenderId: '552536282575',
  appId: '1:552536282575:web:f713c24d4af673b7ef2eba',
  measurementId: 'G-F30L5BKXYN'
}

// init firebase
initializeApp(firebaseConfig)

// init services
const db = getFirestore()
const auth = getAuth()
const storage = getStorage()
const storageRef = ref; //(storage);

export { db, auth, storage, storageRef }